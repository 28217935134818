@import "src/themes/daikiri/styles/index.scss";
.modal {
  @include md {
    &__auth {
      min-width: 21rem;
    }
  }
}

.button--new-password__modal {
  margin-top: space(1.5) !important;
}

.NewPassword__title {
  text-align: left;
}

.subscription__modal {
  padding-top: space(2);
  display: flex;
  flex-direction: column;
  align-items: center;

  &--result {
    h3 {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    button {
      width: 100%;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  button {
    min-width: unset !important;
  }
}

.qr-image {
  border: solid space(0.25) getvar($colors, "primary", "500");
  border-radius: space(0.5);
  box-sizing: border-box;
  margin: space() 0 space(2) 0;
  padding: space(2);
  width: 90%;
}

.qr-frame {
  height: 320px;
  padding: space(2);
  margin: 2rem 1rem;

  @include md {
    //  height: 416px;
    height: 80%;
  }

  svg {
    width: 92%;
    height: 100%;
  }

  img {
    height: 99%;
    width: auto;
  }

  &--image {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    height: 100%;
    border: none !important;
    border-radius: 0 !important;
  }
}

.qr-button {
  width: 100%;
  min-width: auto;

  &--half {
    width: 50%;
  }
}

.download-qr__modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  body {
    line-height: 2rem;
  }

  button {
    margin: space(0.5) 0;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.subscription-modal-buttons_container {
  display: flex;
  flex-direction: column;

  @include md {
    flex-direction: row;
  }

  .button {
    width: 100%;
    min-width: auto;
    margin-bottom: 10px;

    @include md {
      width: 50%;
      margin-bottom: 0;
      margin-right: space(1);
    }
  }
}

.modal-buttons_container {
  display: flex;
  .button:not(:last-child) {
    margin-right: space(1);
  }
}

.form-modal {
  max-width: space(43.75);
  padding: 0 !important;

  p {
    font-size: space(1.125);
    line-height: space(1.5);
    text-align: left;
  }
  .modal-title {
    font-weight: 600;
  }
  .modal-content {
    font-weight: 400;
  }
  &-container .Input {
    margin-bottom: space(1);
  }
  &-container .Input:last-of-type {
    margin-bottom: 0;
  }

  &-container .button {
    min-width: auto;
    width: 30%;
    margin-right: 0px;
    margin-top: 1rem !important;
    padding: 0.85rem 2rem;
  }

  &-container .tooltip {
    padding-left: 8px;
    .tooltip-text.top {
      min-width: 170px;
      padding: 8px 6px;
      left: 58%;
      i::after {
        left: 58%;
      }
    }
    @include sm {
      padding-left: 12px;
    }
    @include lg {
      padding-left: 8px;
    }
  }

  &-container {
    padding: 0 1.08rem;

    .first-row {
      margin-bottom: space(1);
      @include sm {
        margin-bottom: 0;
        display: grid;
        grid-gap: space(1);
      }
    }

    .middle-row-wrapper,
    .middle-row-sub-wrapper,
    .last-row {
      @include sm {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: space(1);
      }
    }
    .middle-row-wrapper,
    .middle-row-sub-wrapper {
      margin-bottom: space(1);
      @include sm {
        margin-bottom: 0;
      }
    }
  }
}

.userInformation__modal {
  .billing-form__fields {
    .Input__error-message {
      font-size: space(0.875);
    }

    .first-row {
      @include sm {
        grid-template-columns: 1fr 1fr;
      }
    }
    .middle-row-wrapper {
      @include sm {
        width: 50%;
      }
    }

    button {
      width: 100%;

      @include sm {
        min-width: auto;
        width: 30%;
        margin-right: 0px;
        margin-top: 3.5rem !important;
        padding: 0.85rem 2rem;
      }
    }
  }
  .form-modal-container {
    .first-row {
      @include sm {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

.billingInformation__modal {
.form-modal{
  .billing-form-button {
    button {
      margin-right: 0;
    }
  }
  #continue-button-billing {
    display: none;
  }
}

  .form-modal-container {
    .first-row {
      @include sm {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  .form-modal-container .button {
    min-width: auto;
    width: 30%;
    margin-right: 0px;
    margin-top: 3.5rem !important;
    padding: 0.85rem 2rem;
  }
}
